<template>
  <v-container>
    <v-switch
      class="float-right"
      v-if="$privileges.has({ path: '/processingFormRows', permission: 'delete' })"
      id="processing_form_rows_delete"
      v-model="deletingRowsActive"
      color="primary"
      :label="$t('processing_form_rows_delete')"
    ></v-switch>
    <div class="custom-table-styling">
      <v-table class="processingFormPreviewInputError custom-table-styling" density="compact">
        <template v-for="(formPart, fpindex) of processingFormPreviewData" v-bind:key="'fphead' + fpindex">
          <thead>
            <tr>
              <th colspan="10">
                <h2>{{ formPart.ident }} {{ formPart[this.$getLangKey()] }}</h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(fpgroup, fpgindex) of formPart.groups" v-bind:key="'fp' + fpindex + 'fpg' + fpgindex">
              <tr class="fpgroup" :id="'fp' + fpindex + 'fpg' + fpgindex">
                <td name="col1" colspan="2">{{ fpgroup.formPartIdent }}/{{ fpgroup.ident }}/{{ fpgroup.block }}</td>
                <td name="col3" colspan="2">
                  {{ fpgroup[this.$getLangKey()] }}
                </td>
                <td name="col5" v-if="showDebug()">
                  <span v-html="$t('processing_form_preview_animal_type')" />
                </td>
                <td name="col6" v-if="showDebug()">
                  <span v-html="$t('processing_form_preview_status')" />
                </td>
                <td name="col7">
                  <span v-html="$t('processing_form_preview_milklabel')" />
                </td>
                <td name="col8">
                  <label for="quantity1">{{ fpgroup['label1' + $route.params.upLang] }}</label>
                </td>
                <td name="col9">
                  <label for="quantity2">{{ fpgroup['label2' + $route.params.upLang] }}</label>
                </td>
                <td name="col10">
                  <label v-if="fpgroup.askFat" for="text1" v-html="$t('processing_form_preview_fat')"></label>
                  <label v-if="fpgroup.askLoafCount" for="text2" v-html="$t('processing_form_preview_loafCount')"></label>
                </td>
              </tr>

              <!-- https://tsmtreuhand.atlassian.net/browse/DB2020-1194 -->
              <template v-for="(gRow, rowIndex) of fpgroup.rows" v-bind:key="'fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + rowIndex">
                <tr
                  v-if="
                    (gRow.productCode === '111' || gRow.productCode === '376') &&
                    !$privileges.has({
                      path: '/processingForms/completion/controlling',
                      permission: 'read'
                    })
                  "
                ></tr>
                <tr
                  :disabled="
                    (gRow.productCode === '111' || gRow.productCode === '376') &&
                    $privileges.has({
                      path: '/processingForms/completion/controlling',
                      permission: 'read'
                    })
                  "
                  v-else
                >
                  <template v-if="gRow.versionNo === 1">
                    <td name="col1" class="text-end">
                      {{ gRow.rowTypeIdent }}
                    </td>
                    <td name="col2" class="text-end">{{ gRow.productCode }}</td>
                    <td name="col3">
                      {{ gRow['rowType' + this.$getUpLangKey()] }}
                    </td>
                    <td name="col4">
                      {{ gRow['product' + this.$getUpLangKey()] }}
                    </td>
                    <td name="col5" v-if="showDebug()">
                      {{ gRow.animalTypes[0][this.$getLangKey()] }}
                    </td>
                    <td name="col6_status" v-if="showDebug()">
                      {{ gRow.applicationFormStatusIdent }}
                    </td>
                  </template>
                  <template v-else>
                    <!-- else case first columns when it is a correction -->
                    <td name="col7" colspan="4">
                      <span v-html="$t('processing_form_preview_correction_course')" />:
                      {{ gRow.versionNo - 1 }}
                      <span v-html="$t('processing_form_preview_difference')" />: {{ gRow.quantityCalculated }}
                    </td>
                    <td name="col5" v-if="showDebug()">&nbsp;</td>
                    <td name="col5" v-if="showDebug()">&nbsp;</td>
                  </template>
                  <td name="col7">
                    {{ translateMilkLabel(gRow.milkLabel) }}
                  </td>
                  <!-- eingang -->
                  <td name="col8">
                    <template v-if="gRow.rowTypeColumnNr === 1">
                      <vNumberField
                        class="right-aligned-input nomessage"
                        :filled="true"
                        :name="'QUANTITY_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + rowIndex"
                        v-model="gRow.quantity"
                        @change="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.QUANTITY)"
                        @keyup.enter="$goNext($event.target)"
                        @focus.native="saveInitialRowValues(gRow)"
                        density="compact"
                        :disabled="gRow.isDisabled"
                        :rules="[$rules.isNumeric]"
                        :id="'quantity_' + gRow.code + '_' + gRow.versionNo + '_1'"
                        :precision="0"
                        @keydown.enter.prevent="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.QUANTITY)"
                        hide-details
                        :loading="gRow.loading"
                      ></vNumberField>
                    </template>
                  </td>
                  <td name="col9">
                    <template v-if="gRow.rowTypeColumnNr === 2">
                      <vNumberField
                        :name="'ROWTYPECOLUMN2_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + rowIndex"
                        :filled="true"
                        v-model="gRow.quantity"
                        class="right-aligned-input nomessage"
                        @change="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.QUANTITY)"
                        @keyup.enter="$goNext($event.target)"
                        density="compact"
                        :key="'numberfield_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id"
                        :disabled="gRow.isDisabled"
                        :rules="[$rules.isNumeric]"
                        :id="'quantity_' + gRow.code + '_' + gRow.versionNo + '_2'"
                        :precision="0"
                        @keydown.enter.prevent="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.QUANTITY)"
                        hide-details
                        :loading="gRow.loading"
                      ></vNumberField>
                    </template>
                  </td>
                  <td name="col10">
                    <template v-if="gRow.groupAskFat && gRow.productAskFat.includes('YES')">
                      <!-- :label="$t('processing_form_preview_fat')" -->
                      <vNumberField
                        :name="'FAT_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + rowIndex"
                        :filled="true"
                        v-model="gRow.fat"
                        class="right-aligned-input nomessage"
                        @change="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.FAT)"
                        @keydown.enter.prevent="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.FAT)"
                        @keyup.enter="$goNext($event.target)"
                        :key="'askfat_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id"
                        density="compact"
                        :disabled="gRow.isDisabled"
                        :rules="[$rules.isNumeric, isValidFat(gRow)]"
                        :id="'fat' + gRow.code + '_' + gRow.versionNo"
                        :ref="'fat' + gRow.code + '_' + gRow.versionNo"
                        :precision="2"
                        hide-details
                        :loading="gRow.loading"
                      ></vNumberField>
                    </template>
                    <template v-if="gRow.groupAskLoafCount && gRow.productAskLoafCount.includes('YES')">
                      <!-- :label="$t('processing_form_preview_loafCount')" -->
                      <vNumberField
                        :name="'name_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + rowIndex"
                        v-model="gRow.loafCount"
                        :filled="true"
                        class="right-aligned-input nomessage"
                        @change="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.LOAFCOUNT)"
                        @keyup.enter="$goNext($event.target)"
                        density="compact"
                        :disabled="gRow.isDisabled"
                        :rules="[$rules.isNumeric]"
                        :id="'loafCount_' + gRow.code + '_' + gRow.versionNo + '_1'"
                        :precision="0"
                        @keydown.enter.prevent="updateRow(gRow, fpgroup, fpindex, fpgindex, rowIndex, columnNames.LOAFCOUNT)"
                        hide-details
                        :loading="gRow.loading"
                      ></vNumberField>
                    </template>
                  </td>
                  <td name="col12" v-if="deletingRowsActive">
                    <v-btn v-if="showDeletingButton(gRow)" :loading="gRow.loadingDelete" @click="deleteProcessingFormRow(gRow)">
                      <v-icon dbmblueprimary>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <template
                  v-if="
                    mpdOneTotal &&
                    Array.isArray(mpdOneTotal) &&
                    fpgroup.rows[fpgroup.rows.length - 1].id === gRow.id &&
                    gRow.processingFormPartGroupId === mpdOneGroupId
                  "
                >
                  <template
                    v-for="(mpdOneTotalItem, index) of mpdOneTotal"
                    v-bind:key="'mpdOneTotalItem1_fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + 'mptotal' + mpdOneTotalItem.id"
                  >
                    <tr>
                      <td colspan="7">
                        <p id="total mpd1">
                          {{ 'Total MPD1 ' + mpdOneTotalItem['animalType' + this.$getUpLangKey()] }}
                        </p>
                      </td>
                      <td name="col8">
                        <template v-if="mpdOneTotalItem.purchaseFromFarmer">
                          <p id="purchase_from_farmer" dbm-number>
                            {{ $formatNumber(mpdOneTotalItem.sumProductionForm, 0) }}
                          </p>
                        </template>
                      </td>
                      <td name="col9" colspan="2" v-if="showDebug()"></td>
                    </tr>

                    <tr id="mpdOneTotalItem2">
                      <td colspan="7">
                        <p>
                          <span v-html="$t('processing_form_preview_difference_mpd1_tsm1')" />
                        </p>
                      </td>
                      <td name="col8">
                        <p dbm-number>
                          &nbsp;<template v-if="mpdOneTotalItem.purchaseFromFarmer">
                            {{ $formatNumber(mpdOneTotalItem.sumProductionForm - mpdOneTotalItem.purchaseFromFarmer, 0) }}
                          </template>
                        </p>
                      </td>
                      <td name="col9" colspan="2" v-if="showDebug()"></td>
                    </tr>

                    <tr
                      v-if="
                        mpdOneTotalItem.purchaseFromFarmer &&
                        Math.abs(Math.round(mpdOneTotalItem.purchaseFromFarmer) - Math.round(mpdOneTotalItem.sumProductionForm)) > 50
                      "
                    >
                      <td colspan="8">
                        <v-alert color="red" type="warning">
                          <span v-html="$t('processing_form_preview_deviation')" />
                        </v-alert>
                      </td>
                      <td name="col9" colspan="2" v-if="showDebug()"></td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
          <div class="" v-if="formPart.ident === 10 || formPart.ident === 20"></div>
        </template>
      </v-table>
    </div>
    <v-dialog v-model="dialog" max-width="640">
      <v-card class="custom-horizontal-scroll-style">
        <v-card-title><span v-html="$t('processing_form_show_xlsx_rows')" /></v-card-title>
        <v-row>
          <v-col cols="6">
            <v-card-text>
              <!--  <v-btn class="m-2" color="primary" @click="exportTableToCSV" ><span v-html="$t('processing_form_download_xlsx')"/></v-btn>-->
              <v-form ref="fileuploader" @submit.prevent="importFile()">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-file-input
                        ref="xlsx_upload"
                        v-model="selectedFile"
                        :rules="[$rules.required]"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        truncate-length="15"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn :loading="xlsxRows.rowsLeft.length > 0" :disabled="xlsxRows.rowsLeft.length > 0" type="submit">
                        <span v-html="$t('import')"></span
                      ></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-col>

          <v-col cols="6">
            <v-card-text class="custom-vertical-scroll-style">
              <template v-if="inProgrss">
                <h4 v-html="$t('processing_form_rows_found')"></h4>
                <template v-for="(row, index) of xlsxRows.rowsFoundInXlsx" v-bind:key="'xlsx_rows' + row.code + 'indx' + index">
                  <v-list-item>
                    <v-list-item-title>{{ row.code }}</v-list-item-title>
                  </v-list-item>
                </template>
                <h4 v-html="$t('processing_form_rows_skipped')"></h4>
                <template v-for="(row, index) of xlsxRows.rowsSkipped" v-bind:key="'xlsx_rows' + row.code + 'indx' + index">
                  <v-list-item :id="'xlsx_rows' + row.code">
                    <v-list-item-title>{{ row.code }}</v-list-item-title>
                  </v-list-item>
                </template>
                <h4 v-html="$t('processing_form_rows_not_found')"></h4>
                <template v-for="(row, index) of xlsxRows.rowsNotFoundInXlsx" v-bind:key="'xlsx_rows' + row.code + 'indx' + index">
                  <v-list-item :id="'xlsx_rows' + row.code">
                    <v-list-item-title>{{ row.code }}</v-list-item-title>
                  </v-list-item>
                </template>
              </template>
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-btn
            :loading="xlsxRows.rowsLeft.length > 0"
            :disabled="xlsxRows.rowsLeft.length > 0"
            color="primary"
            class="shadow-none"
            @click="dialog = false"
            v-html="$t('close')"
          ></v-btn>
          <v-btn class="m-2" color="secondary" @click="exportTableToCSV"><span v-html="$t('processing_form_download_xlsx')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import _ from 'lodash'
import vNumberField from '../../../components/v-number-field.vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { isAxiosError, showError } from '@/services/axios'
import { useSettingsStore } from '@/store/SettingsStore'
import { useMilkLabelsStore } from '@/store/enums/MilkLabelsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingFormPreview',
  /* props: {
    processingFormId: Number,
    formStatusIdent: Number
  } */
  props: ['processingFormId', 'formStatusIdent'],
  emits: ['rowHasBeenUpdated', 'validateForm'],
  components: {
    vNumberField: vNumberField
  },
  data() {
    return {
      deletingRowsActive: false,
      columnNames: {
        QUANTITY: 'quantity',
        LOAFCOUNT: 'loafCount',
        FAT: 'fat'
      },
      selectedFile: null,
      xlsxRows: {
        rowsLeft: [],
        rowsFoundInXlsx: [],
        rowsSkipped: [],
        rowsNotFoundInXlsx: []
      },
      processingFormPreviewData: [],
      name: '',
      mpdOneTotal: [],
      mpdOneGroupId: 0,
      dialog: false
    }
  },
  computed: {
    milkLabels() {
      return useMilkLabelsStore().milkLabels
    },
    producerTotal() {
      return this.processingFormPreviewData[0].groups[0].rows.filter((x) => x.formRowTypeId === 2)[0].quantity
    },
    inProgrss() {
      return (
        this.xlsxRows.rowsLeft.length + this.xlsxRows.rowsFoundInXlsx.length + this.xlsxRows.rowsSkipped.length + this.xlsxRows.rowsNotFoundInXlsx.length > 0
      )
    }
  },
  methods: {
    showDeletingButton(gRow) {
      // In order to delete status can't be > 40
      if (gRow.applicationFormStatusIdent > 40) {
        return false
      }
      // In order to delete  can't have child rows
      if (gRow.childRowId) {
        return false
      }
      // In order to delete  can't have parent rows
      if (gRow.parentRowId) {
        return false
      }
      // In order to delete quantity can't have a value
      if (gRow.quantity != null && gRow.quantity != undefined && gRow.quantity != 0) {
        return false
      }
      // In order to delete fat can't have a value
      if (gRow.fat != null && gRow.fat != undefined && gRow.fat != 0) {
        return false
      }
      // In order to delete loafCount can't have a value
      if (gRow.loafCount != null && gRow.loafCount != undefined && gRow.loafCount != 0) {
        return false
      }
      return true
    },
    async deleteProcessingFormRow(gRow) {
      gRow.loadingDelete = true
      try {
        const result = await this.axios.delete(apiURL + '/processingFormRows', { params: { id: gRow.id } }, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.load()
          emitter.emit('toastSuccess', {
            message: this.$t('processing_form_row_delete_success_toast')
          })
        }
      } catch (e) {
        showError(e)
      } finally {
        gRow.loadingDelete = false
      }
    },
    translateMilkLabel(labelName) {
      let labels = this.milkLabels.filter((e) => e.name == labelName)
      return Array.isArray(labels) && labels.length ? labels[0][this.$getLangKey()] : 'none'
    },
    onButtonClick() {
      this.$refs.xlsx_upload.click()
    },
    findProcessingFormRowByCode(code) {
      try {
        const codefragments = code.split('.')
        let formpart = this.processingFormPreviewData.filter((e) => e.ident == codefragments[0])[0]
        let formpartgroup = formpart.groups.filter((e) => e.ident == codefragments[1] && e.block == parseInt(codefragments[2]))[0]
        let row = formpartgroup.rows.filter((fpg) => fpg.code == code)[0]
        return row
      } catch (e) {
        return
      }
    },
    getFormPartIdentFromCode(code) {
      return code.split('.')[0]
    },
    getFormPartGroupIdentFromCode(code) {
      return code.split('.')[1]
    },
    getFormRowTypeFromCode(code) {
      return code.split('.')[3]
    },
    async updateOneXlsRow(xlsxRow) {
      if (!xlsxRow) {
        // end of recursion
        this.load()
        return
      }

      let row = this.findProcessingFormRowByCode(xlsxRow.code)

      if (!row) {
        this.xlsxRows.rowsNotFoundInXlsx.push(row)
        this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
        return
      }
      row.quantity = xlsxRow.quantity
      row.loafCount = xlsxRow.loafCount
      row.fat = xlsxRow.fat

      // anfangsvorrat ohne Buttergruppe ;initialstock without buttergroup
      if (this.getFormPartIdentFromCode(row.code) == 10 && this.getFormPartGroupIdentFromCode(row.code) != 60 && this.getFormRowTypeFromCode(row.code) == 0) {
        this.xlsxRows.rowsSkipped.push(row)
        this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
        return
      }

      try {
        const result = await this.axios.patch(apiURL + '/processingFormRows' + '/' + row.id, {
          quantity: parseFloat(parseFloat(row.quantity)?.toFixed(2)),
          fat: parseFloat(parseFloat(row.fat)?.toFixed(2)),
          loafCount: parseFloat(parseFloat(row.loafCount)?.toFixed(2))
        })
        if (await result) {
          this.xlsxRows.rowsFoundInXlsx.push(row)
          this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
          return
        }
      } catch (e) {
        this.xlsxRows.rowsNotFoundInXlsx.push(row)
        this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
        return
      }
    },
    startUpdateXLSXRows(xlsxRows) {
      this.xlsxRows.rowsFoundInXlsx = []
      this.xlsxRows.rowsNotFoundInXlsx = []
      this.xlsxRows.rowsSkipped = []
      this.xlsxRows.rowsLeft = xlsxRows
      // anfangsvorrat ohne Buttergruppe wird nicht überschrieben
      this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
    },
    //vermutlich deadcode
    onFileChanged(e: any) {
      this.importFile(e)
    },
    async importFile(e: any) {
      if (!(await this.$refs.fileuploader.validate()).valid) {
        return
      }

      this.xlsxRows.rowsFoundInXlsx = []
      this.xlsxRows.rowsNotFoundInXlsx = []

      const file = this.selectedFile
      var reader = new FileReader()
      reader.onload = (e) => {
        var data = e.target.result
        var workbook = XLSX.read(data, {
          type: 'binary'
        })
        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          var XLSX_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
          XLSX_row_object.reverse()
          this.startUpdateXLSXRows(XLSX_row_object)
        })
      }
      reader.onerror = function (ex) {
        showError(ex)
      }
      reader.readAsBinaryString(file)
    },

    getDownloadData() {
      let rows = []

      this.processingFormPreviewData.forEach((x) => {
        x.groups.forEach((y) => {
          y.rows.forEach((row) => {
            row = _.pick(row, ['code', 'product' + this.$getUpLangKey(), 'milkLabel', 'quantity', 'loafCount', 'fat'])
            rows.push(row)
          })
        })
      })
      return rows
    },
    exportTableToCSV() {
      // https://docs.sheetjs.com/
      var data = this.getDownloadData()
      var workbook = XLSX.utils.book_new()
      var worksheet_name = 'SheetJS'
      var worksheet = XLSX.utils.json_to_sheet(data)

      /* change cell format of quantity and loafCount (from https://github.com/SheetJS/sheetjs/issues/966) */
      var format = '#,##0' // https://docs.sheetjs.com/docs/csf/features/#number-formats
      var range = { s: { r: 1, c: 3 }, e: { r: data.length, c: 4 } }
      for (var R = range.s.r; R <= range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
          var cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
          // cell.t = 'n'
          cell.z = format
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, worksheet_name)
      XLSX.writeFile(workbook, 'gesuch.xlsx')
    },
    openDialog() {
      this.xlsxRows.rowsFoundInXlsx = []
      this.xlsxRows.rowsNotFoundInXlsx = []
      this.xlsxRows.rowsSkipped = []
      this.dialog = true
    },
    showDebug() {
      let settingsStore = useSettingsStore()
      return settingsStore.getDebug
    },

    async updateRow(gRow, fpgroup, formPartIndex, groupIndex, rowIndex, columnName) {
      if (gRow.groupAskFat) {
        if (gRow.quantity) {
          this.setBlur(gRow)
        }
        this.setValidFat(gRow)
      }
      gRow.loading = true

      try {
        const result = await this.axios.patch(
          apiURL + '/processingFormRows' + '/' + gRow.id,
          {
            id: gRow.id,
            fat: columnName === 'fat' ? parseFloat(parseFloat(gRow.fat)?.toFixed(2)) : undefined,
            loafCount: columnName === 'loafCount' ? parseFloat(parseFloat(gRow.loafCount)?.toFixed(2)) : undefined,
            quantity: columnName === 'quantity' ? parseFloat(parseFloat(gRow.quantity)?.toFixed(2)) : undefined,
            formRowType: {
              id: gRow.formRowTypeId
            },
            milkLabels: gRow.milkLabels
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          let nextRow = result.data
          this.prepareDisabledInputFields(nextRow, this.processingFormPreviewData[formPartIndex].groups[groupIndex])

          if (nextRow.id !== gRow.id) {
            // correction
            const oldRow = await this.axios.get(apiURL + '/processingFormRows' + '/' + gRow.id)
            if (await oldRow) {
              gRow.fat = oldRow.data.fat
              gRow.quantity = oldRow.data.quantity
              gRow.loafCount = oldRow.data.loafCount
              this.prepareDisabledInputFields(gRow, fpgroup)
              gRow.isDisabled = true
              gRow.quantity = gRow.quantityCalculated
            }
            this.processingFormPreviewData[formPartIndex].groups[groupIndex].rows.splice(rowIndex + 1, 0, nextRow)
          } else {
            // update
            gRow.quantityCalculated = nextRow.quantityCalculated
            if (columnName === this.columnNames.FAT) gRow.fat = nextRow.fat
            if (columnName === this.columnNames.QUANTITY) gRow.quantity = nextRow.quantity
            if (columnName === this.columnNames.LOAFCOUNT) gRow.loafCount = nextRow.loafCount
          }
          this.$emit('rowHasBeenUpdated')
          if (gRow.processingFormPartId === 1 && gRow.rowTypeIdent === 1) {
            this.mpdOneTotal = await this.getMpdOneTotal()
          }
        }
      } catch (e) {
        if (isAxiosError(e)) {
          showError(e)
        } else {
          emitter.emit('toastError', {
            message: this.$t('processing_form_quantity_not_saved')
          })
        }
      } finally {
        gRow.loading = false
      }
    },
    showLoader(id) {
      return this.loading[id]
    },
    async getMpdOneTotal() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id + '/processingFormAndMarketedMilkCrossCheck',
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async load() {
      try {
        const response = await this.axios.get(apiURL + '/processingForms/' + this.processingFormId + '/processingFormPreview', {
          headers: { Accept: 'application/json' }
        })
        const data = await response.data
        this.processingFormPreviewData = this.prepareAllData(data)
      } catch (e) {
        showError(e)
      }
    },
    prepareAllData(data) {
      this.mpdOneGroupId = 0
      data.forEach((formpart) => {
        formpart.groups.forEach((group) => {
          group.rows.forEach((row) => {
            // MPD1-Quervergleich am Ende der ersten Gruppe mit "direkt von Produzenten" ausgeben
            if (row.rowTypeIdent === 1 && this.mpdOneGroupId === 0) this.mpdOneGroupId = row.processingFormPartGroupId
            this.prepareDisabledInputFields(row, group)
          })
        })
      })
      return data
    },
    prepareDisabledInputFields(row, group) {
      row.isValidFat = true
      row.isDisabled = false

      //if the row is a child, its parentrow must always be disabled
      if (row.parentRowId !== null) {
        //disable the parent row
        let parentRow = group.rows.filter((parentRow) => parentRow.id == row.parentRowId)[0]
        parentRow.isDisabled = true
      }

      // berechnete Felder -> disabled
      if (
        row.productCode === '111' ||
        row.productCode === '376' ||
        (row.productCode === '116' && group.ident === 39) ||
        (row.productCode === '117' && group.ident === 109)
      ) {
        row.isDisabled = true
      }

      // if the user is of the type 'tsm', allow editing up until form status ident 40  DB2020-1126
      // if the user is of the type 'verwerter', do not allow editing if the form status ident is 40 or higher DB2020-1209
      // if the user is of the type 'blw', disable all the input fields.   DB2020-1126
      if (
        !this.$privileges.has({
          path: '/processingFormRows/enabledWhenStatusEquals',
          permission: this.formStatusIdent.toString()
        })
      ) {
        row.isDisabled = true
      }

      // Anfangsvorrat, nicht TSM -> disabled ohne Buttergruppe
      if (
        group.formPartIdent === 10 &&
        group.ident != 60 &&
        row.rowTypeIdent === 0 &&
        !this.$privileges.has({
          path: '/processingForms',
          permission: 'editInitialStock'
        })
      ) {
        row.isDisabled = true
      }

      return row.isDisabled
    },
    setBlur(gRow) {
      // quantity.blur enables validFat
      gRow.blur = true
    },
    setValidFat(gRow) {
      if (!gRow.blur) {
        return true
      }

      // productAskFat Enum YES | YES_REQUIRED | NO
      if (!gRow.productAskFat === 'YES') {
        // fat ist optional
        gRow.isValidFat = true
      } else if (gRow.productAskFat === 'YES_REQUIRED' && ((gRow.quantity && gRow.fat) || (!gRow.quantity && !gRow.fat))) {
        gRow.isValidFat = true
      } else if (gRow.isValidFat && !gRow.fat) {
        gRow.fat = '' // this will trigger vuetify rules rules
        gRow.isValidFat = false
      } else {
        gRow.isValidFat = false
      }
    },
    isValidFat(row, element) {
      this.setValidFat(row)
      return row.isValidFat
    }
  },
  watch: {
    formStatusIdent: {
      handler(newValue) {
        if (this.processingFormPreviewData.length) {
          this.prepareAllData(this.processingFormPreviewData)
        } else {
          this.load()
        }
      }
    }
  },
  beforeMount() {
    useMilkLabelsStore().fetchMilkLabels()
  },
  async mounted() {
    this.load()
    this.mpdOneTotal = await this.getMpdOneTotal()
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.v-text-field__slot input {
  caret-color: $dbm-black;
}

table {
  tr.formPart td {
    font-size: 1em;
    font-weight: bolder;
  }

  tr.fpgroup td {
    font-size: 0.8em;
    font-weight: bold;
  }

  tr.gRow td {
    font-size: 0.6em;
  }
}

.loading-dialog {
  background-color: $dbm-overlay-color !important;
}

// nicht editierbare zellen
.custom-table-styling > .v-table__wrapper .v-input--is-readonly .v-input__slot > .v-text-field__slot {
  background: $dbm-white !important;
}

// abgeschlossen
.custom-table-styling > .v-table__wrapper .v-input--is-disabled .v-input__slot > .v-text-field__slot,
.custom-table-styling > .v-table__wrapper .v-input--is-disabled .v-select__slot {
  background: $dbm-light-grey !important;
}
.custom-vertical-scroll-style {
  height: 200px;
  overflow-y: auto;
}

.custom-horizontal-scroll-style {
  overflow-x: hidden;
}
</style>
