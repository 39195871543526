<template>
  <div class="processingFormHeaderContainer">
    <v-row></v-row>
    <v-row
      ><v-col cols="6"></v-col>
      <v-col cols="6">
        <div
          v-if="
            $privileges.has({
              path: '/processingForms',
              permission: 'write'
            }) && entireProcessingFormObject.formPeriod == 'SEASONAL'
          "
        >
          <!-- Von -->
          <DbmDatePicker
            :required="true"
            :readonly="isReadOnly('validFrom', entireProcessingFormObject)"
            v-model="entireProcessingFormObject.validFrom"
            @change="
              (emittedDate) => {
                entireProcessingFormObject.validFrom = emittedDate
                setProcessingPeriod('validFrom')
              }
            "
            :dateLabel="$t('processing_form_header_summer_from')"
          />
          <!-- Bis -->
          <DbmDatePicker
            :readonly="isReadOnly('validUntil', entireProcessingFormObject)"
            v-model="entireProcessingFormObject.validUntil"
            @change="
              (emittedDate) => {
                entireProcessingFormObject.validUntil = emittedDate
                setProcessingPeriod('validUntil')
              }
            "
            :required="true"
            :dateLabel="$t('processing_form_header_summer_until')"
          />
        </div>
        <div
          v-else-if="
            $privileges.has({
              path: '/processingForms',
              permission: 'write'
            }) && entireProcessingFormObject.formPeriod == 'SIX_MONTHLY'
          "
        >
          <!-- Von -->
          <DbmDatePicker
            :required="true"
            v-model="entireProcessingFormObject.validFrom"
            @change="
              (emittedDate) => {
                entireProcessingFormObject.validFrom = emittedDate
                setProcessingPeriod('validFrom')
              }
            "
            :dateLabel="$t('processing_form_header_summer_from')"
          />
          <!-- Bis -->
          <DbmMonthPicker
            :readonly="isReadOnly('validUntil', entireProcessingFormObject)"
            :required="true"
            v-model="entireProcessingFormObject.validUntil"
            @change="
              (emittedDate) => {
                entireProcessingFormObject.validUntil = emittedDate
                setProcessingPeriod('validUntil')
              }
            "
            :dateLabel="$t('processing_form_header_summer_until')"
            returnType="lastOfMonth"
          />
        </div>
        <div v-else-if="entireProcessingFormObject.formPeriod == 'REPEATEDLY_SEASONAL'">
          <!-- REPEATEDLY_SEASONAL Ist die Sömmerungsmeldung für dieses Jahr beendet? -->
          <v-switch
            :disabled="isReadOnly('validUntil', entireProcessingFormObject)"
            id="processing_form_header_summer_question"
            v-model="entireProcessingFormObject.alpSummeringEnd"
            color="primary"
            :label="$t('processing_form_header_summer_question')"
            @change="setProcessingPeriod('alpSummeringEnd')"
          ></v-switch>
          <div>
            <!-- Von -->
            <DbmDatePicker
              :readonly="isReadOnly('validFrom', entireProcessingFormObject)"
              v-model="entireProcessingFormObject.validFrom"
              @change="
                (emittedDate) => {
                  entireProcessingFormObject.validFrom = emittedDate
                  setProcessingPeriod('validFrom')
                }
              "
              :required="true"
              :dateLabel="$t('processing_form_header_summer_from')"
            />
            <!-- Bis -->
            <div v-if="entireProcessingFormObject.alpSummeringEnd == true">
              <DbmDatePicker
                :readonly="isReadOnly('validUntil', entireProcessingFormObject)"
                v-model="entireProcessingFormObject.validUntil"
                :required="true"
                @change="setProcessingPeriod('validUntil')"
                :dateLabel="$t('processing_form_header_summer_until')"
              />
            </div>
            <div v-if="entireProcessingFormObject.alpSummeringEnd == false">
              <DbmMonthPicker
                :readonly="isReadOnly('validUntil', entireProcessingFormObject)"
                :required="true"
                v-model="entireProcessingFormObject.validUntil"
                @change="setProcessingPeriod('validUntil')"
                :dateLabel="$t('processing_form_header_summer_until')"
                returnType="lastOfMonth"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <span class="bold" v-html="$t('processing_form_header_request_period')" />:
            {{ $moment(entireProcessingFormObject.validFrom).format('DD.MM.YYYY') }} - {{ $moment(entireProcessingFormObject.validUntil).format('DD.MM.YYYY') }}
            <!-- <span v-html="$t('processing_form_header_summer_from')" /> {{ entireProcessingFormObject.validFrom }} <span v-html="$t('processing_form_header_summer_until')" /> {{ entireProcessingFormObject.validUntil }} -->
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"></v-col>
      <v-col cols="6">
        <!-- <p @click="toggleDialog()" :class="computeCSSClass()"><span v-html="$t('processing_form_header_request_period')" />: {{ $moment(entireProcessingFormObject.validFrom).format('DD.MM.YYYY') }} - {{ $moment(entireProcessingFormObject.validUntil).format('DD.MM.YYYY') }}</p> -->
        <!-- <span class="bold" v-html="$t('processing_form_header_request_period')" />: {{ $moment(entireProcessingFormObject.validFrom).format('DD.MM.YYYY') }} - {{ $moment(entireProcessingFormObject.validUntil).format('DD.MM.YYYY') }} -->
        <p v-if="entireProcessingFormObject.versionNo > 1">
          <span v-html="$t('processing_form_header_correction_course')" />
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <pre>{{ entireProcessingFormObject.dairyFormattedAddress }}</pre>
        <br />
      </v-col>

      <v-col cols="6">
        <v-row>
          <v-col cols="6" class="mt-0 pt-0">
            <span v-html="$t('processing_form_header_code')" /><br />
            <span v-html="$t('processing_form_header_request_request_id')" /><br />
            <span v-html="$t('processing_form_header_mbh_ident')" /><br />
            <span v-html="$t('processing_form_header_petitioner_phone')" /><br />
            <b v-html="$t('processing_form_header_company_form')"></b><br />
            <span v-html="$t('processing_form_header_collaborator')" /><br />
          </v-col>

          <v-col cols="6" class="mt-0 pt-0">
            {{ entireProcessingFormObject.code }}<br />
            {{ entireProcessingFormObject.ident }}<br />
            {{ entireProcessingFormObject.dairyIdent }}<br />
            {{ entireProcessingFormObject.dairyPhone1 }}<br />
            <b class="bold" v-if="dairyTypes.length">{{ dairyName(entireProcessingFormObject.dairyType) }}</b
            ><br />
            {{ entireProcessingFormObject['responsibilityGroupName' + $route.params.upLang] }}<br />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <p v-if="entireProcessingFormObject.versionNo > 1">
      <span v-html="$t('processing_form_header_correction')" />:
      {{ entireProcessingFormObject.versionNo - 1 }}
    </p>
  </div>
</template>

<script lang="ts">
// todo enum laden dairyType
import { privileges } from '@/services/privileges'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { emitter, apiURL } from '@/main'
import { showError } from '@/services/axios'
import { useDairyTypesStore } from '@/store/enums/DairyTypesStore'
import type { components } from '@/lib/api/v1.d.ts'
type ProcessingFormHeaderDTO = components['schemas']['ProcessingFormHeaderDTO']

import { defineComponent } from 'vue'
export default defineComponent({
  props: ['entireProcessingFormObject'],
  data() {
    return {
      validFromValidValue: this.entireProcessingFormObject.validFrom,
      validUntilValidValue: this.entireProcessingFormObject.validUntil
    }
  },
  computed: {
    dairyTypes() {
      return useDairyTypesStore().dairyTypes
    }
  },
  methods: {
    dairyName(name: string): string {
      let dairyTxpeX = this.dairyTypes.filter((elemX) => elemX.name === name)[0]
      return dairyTxpeX[this.$getLangKey()]
    },
    async setProcessingPeriod(fieldName: string) {
      setTimeout(async () => {
        const value = this.entireProcessingFormObject[fieldName]
        const valuesToPatch = {} as any
        valuesToPatch[fieldName] = value
        const id = this.$route.params.id
        try {
          const result = await this.axios.patch(apiURL + '/processingForms/' + id, valuesToPatch, { headers: { 'Content-Type': 'application/json' } })
          if (await result) {
            emitter.emit('toastSuccess', {
              message: this.$t('processeing_form_header_patch_toast_success')
            })
            switch (fieldName) {
              case 'validFrom':
                this.validFromValidValue = result.data.validFrom
              case 'validUntil':
                this.validUntilValidValue = result.data.validUntil
              default:
            }
          }
        } catch (e) {
          showError(e)
          switch (fieldName) {
            case 'validFrom':
              this.entireProcessingFormObject.validFrom = this.validFromValidValue
            case 'validUntil':
              this.entireProcessingFormObject.validUntil = this.validUntilValidValue
            default:
          }
        }
      }, 100)
    },
    // https://tsmtreuhand.atlassian.net/wiki/spaces/dbmilch2020/pages/804619406/Milchverwertung+Gesch+ftsregeln+-+TSM1#Milchverwertung%2FMilchverwertung%2FGesuche%2FGesuch
    isEditable(fieldName: string, form: ProcessingFormHeaderDTO) {
      if (form.formPeriod == 'REPEATEDLY_SEASONAL') {
        // abbruchbedingungen

        if (privileges.has({ path: '/processingForms', permission: 'isProcessingAdmin' })) {
          //tsm:  "in prüfung tsm"
          if (form.formStatusIdent > 40) {
            return false
          }
        } else {
          //verwerter in prüfung verwerter
          if (form.formStatusIdent > 30) {
            return false
          }
        }
        if (fieldName == 'validFrom') {
          // REPEATEDLY_SEASONAL form chains are numbered by summeringFormNumber. only form number 1 is allowed to set the validFrom.
          // so block higher numbers by returning false.
          // number 1 needs to check the fromstatus as below
          return form.alpSummeringFormNumber > 1 ? false : true
        }
        if (fieldName == 'validUntil') {
          // REPEATEDLY_SEASONAL form chains are numbered by summeringFormNumber
          return true
        }
      }
      if (form.formPeriod == 'SEASONAL') {
        // abbruchbedingungen

        if (privileges.has({ path: '/processingForms', permission: 'isProcessingAdmin' })) {
          //tsm:  "in prüfung tsm"
          return form.formStatusIdent > 40 ? false : true
        } else {
          //verwerter in prüfung verwerter
          return form.formStatusIdent > 30 ? false : true
        }
      }
      if (form.formPeriod == 'SIX_MONTHLY') {
        if (privileges.has({ path: '/processingForms', permission: 'isProcessingAdmin' })) {
          if (fieldName == 'validFrom') {
            return form.formStatusIdent > 70 ? false : true
          }
          if (fieldName == 'validUntil') {
            return form.formStatusIdent > 40 ? false : true
          }
        } else {
          //verwerter nicht editierbar
          return false
        }
      }

      // MONTHLY nicht bearbeitbar
      return false
    },
    isReadOnly(fieldName: string, form: ProcessingFormHeaderDTO) {
      return !this.isEditable(fieldName, form)
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker,
    DbmDatePicker: DbmDatePicker
  },
  beforeMount() {
    useDairyTypesStore().fetchDairyTypes()
  },
  mounted() {
    this.validFromValidValue = this.entireProcessingFormObject.validFrom
    this.validUntilValidValue = this.entireProcessingFormObject.validUntil
  }
})
</script>

<style>
.processingFormHeaderContainer {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.list-group {
  text-align: left;
}

.bold {
  font-weight: 700;
}

.bootstrap pre {
  font-size: 1em !important;
}
</style>
