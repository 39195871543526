<template>
  <v-container>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-form ref="processingForm" name="processingForm" @submit.prevent="goForward()" class="form form--login grid" autocomplete="off">
      <v-container>
        <v-row>
          <v-col cols="12"><h3 v-html="$t('processing_form_edit_title')"></h3></v-col>
          <v-col cols="12"><h5 v-html="$t(generateCustomSubHeaderTextfield())"></h5></v-col>
          <v-col cols="12"><h5 v-html="$t(computeNecessaryInfo())"></h5></v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <div v-if="entireProcessingFormObject">
              <ProcessingFormHeader :entireProcessingFormObject="entireProcessingFormObject" />
            </div>
          </v-col>

          <v-col cols="4">
            <v-row>
              <v-col cols="8" v-if="formStatusLength > 0">
                <v-menu
                  offset-y
                  v-if="
                    $privileges.has({
                      path: '/processingForms',
                      permission: 'acceptErrors'
                    })
                  "
                >
                  <template v-slot:activator="{ props }">
                    <v-btn color="secondary" v-bind="props">
                      <span v-html="$t('processing_form_edit_status')" />:
                      {{ selectedStatusText }}
                      <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="this.currentformStatus.ident > 30 && this.currentformStatus.ident < 90"
                      key="processing_form_verwerter_in_examination"
                      @click="statusWorkflowHelper(currentformStatus.ident, 30)"
                    >
                      <v-list-item-title v-html="$t('processing_form_verwerter_in_examination')"></v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="this.currentformStatus.ident > 40 && this.currentformStatus.ident < 90"
                      key="processing_form_tsm_in_examination"
                      @click="statusWorkflowHelper(currentformStatus.ident, 40)"
                    >
                      <v-list-item-title v-html="$t('processing_form_tsm_in_examination')"></v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      key="processing_form_correction"
                      v-if="currentformStatus.ident === 90"
                      @click="statusWorkflowHelper(currentformStatus.ident, 20)"
                    >
                      <v-list-item-title><span v-html="$t('processing_form_correction')" /> 20</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      key="processing_form_correction_20"
                      v-if="currentformStatus.ident === 90"
                      @click="statusWorkflowHelper(currentformStatus.ident, 30)"
                    >
                      <v-list-item-title><span v-html="$t('processing_form_correction')" /> 30</v-list-item-title>
                    </v-list-item>

                    <!--<v-list-item key="processing_form_debug_test" @click="updateStatus(90)">
                    <v-list-item-title><span v-html="$t('processing_form_debug_test')" /> 90</v-list-item-title>
                  </v-list-item>-->
                  </v-list>
                </v-menu>
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ props }">
                    <v-btn color="secondary" v-bind="props">
                      <span v-html="$t('processing_form_edit_status')" />:
                      {{ selectedStatusText }}
                      <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      key="processing_form_correction_20"
                      v-if="currentformStatus.ident === 90"
                      :disabled="
                        !$privileges.has({
                          path: '/processingForms/updateStatusTo',
                          permission: '30'
                        })
                      "
                      @click="statusWorkflowHelper(currentformStatus.ident, 30)"
                    >
                      <v-list-item-title><span v-html="$t('processing_form_correction')" /> 30</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="primary"
                  :disabled="!(entireProcessingFormObject?.productionFormId && entireProcessingFormObject?.dairyProductionReportingObligation == 'YES_ONLINE')"
                  class="shadow-none"
                  :to="{ name: 'productionForms_read', query: productionFormsReadTerm }"
                  ><span v-html="$t('processing_form_direct_to_mpd')"
                /></v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="primary"
                  :disabled="
                    !(entireProcessingFormObject?.segmentationFormId && entireProcessingFormObject?.dairySegmentationReportingObligation == 'YES_ONLINE')
                  "
                  class="shadow-none margin-left"
                  :to="{ name: 'segmentationForms_edit', params: { id: this.entireProcessingFormObject.segmentationFormId } }"
                  ><span v-html="$t('processing_form_direct_to_bom')"
                /></v-btn>
              </v-col>
            </v-row>

            <v-row class="search">
              <v-col class="item">
                <rdhComponent
                  :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
                  :remarks_show="true"
                  :remarks_tag="'processingForms_' + $route.params.id"
                  :documents_show="true"
                  :documents_tag="'processingForms_' + $route.params.id"
                  :history_show="true"
                  :history_url="'/processingForms/' + $route.params.id"
                  ref="rdh"
                ></rdhComponent>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <div>
              <processingFormPreview
                :processingFormId="Number.parseInt($route.params.id)"
                :formStatusIdent="this.currentformStatus.ident"
                ref="processingFormPreviewRef"
                @validateForm="validateForm"
                @rowHasBeenUpdated="rowHasBeenUpdated"
              />
            </div>
          </v-col>
        </v-row>

        <div></div>
        <v-row>
          <v-col sm="12">
            <v-btn class="m-2" id="cancel" color="secondary" @click="goBack"><span v-html="$t('processing_form_cancel')" /></v-btn>
            <span class="m-2">
              <v-menu class="m-2" offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn id="invoice_zulage_btn" color="secondary" v-bind="props" :disabled="hideSubsidy()">
                    <span v-html="$t('processing_form_invoice_zulage')" />
                    <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item id="show" key="Anzeigen" @click="subsidyShow">
                    <v-list-item-title v-html="$t('processing_form_show')"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>

            <span class="m-2">
              <v-menu class="m-2" offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn id="formular" color="secondary" v-bind="props">
                    <span v-html="$t('processing_form_form')" />
                    <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-show="$privileges.has({ path: '/processingFormTemplates', permission: 'write' })"
                    id="print_btn"
                    key="processing_form_tsm1"
                    @click="goToRelatedFormTemplate"
                  >
                    <v-list-item-title v-html="$t('processing_form_change_tsm1')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="this.entireProcessingFormObject && this.entireProcessingFormObject.versionNo !== 1"
                    @click="exportCsv"
                    id="export_btn"
                    key="processing_form_export"
                  >
                    <v-list-item-title v-html="$t('processing_form_xlsx_export_import')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="print_btn" key="processing_form_print" @click="print">
                    <v-list-item-title v-html="$t('processing_form_print')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="update_btn" key="processing_form_update" @click="updateProcessingForm">
                    <v-list-item-title v-html="$t('processing_form_update')"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>

            <span class="m-2"
              ><v-menu class="m-2" offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn id="protocol_btn" color="secondary" v-bind="props">
                    <span v-html="$t('processing_form_protocol')" />
                    <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item id="adjustment_btn" key="processing_form_export">
                    <v-list-item-title @click="createEnversProtocol" v-html="$t('processing_form_adjustment_button')"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <v-btn
              id="print_btn"
              class="m-2"
              color="secondary"
              :to="{ name: 'processingForms_edit_form_step_four', params: { id: this.entireProcessingFormObject.id } }"
              v-if="
                (entireProcessingFormObject?.formStatusIdent >= 70 || entireProcessingFormObject?.versionNo > 1) &&
                privileges.has({ path: '/processingForms/reports/paper', permission: 'read' })
              "
              ><span v-html="$t('processing_form_go_to_print')"
            /></v-btn>
            <v-btn id="check_btn" class="m-2" color="primary" type="submit" v-html="$t('processing_form_go_to_check')"></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-dialog v-if="entireProcessingFormObject" :retain-focus="false" v-model="showDialogCorrectionReason" max-width="75%" id="dialog" ref="anotherREF">
      <v-card>
        <v-form
          @submit.prevent="completeCorrection('showDialogCorrectionReason')"
          ref="correctionReasonForm"
          name="correctionReasonForm"
          id="correctionReasonForm"
        >
          <v-card-title><span v-html="$t('correction_reason_dialogue_title')" /></v-card-title>
          <v-card-text>
            <v-radio-group v-model="entireProcessingFormObject.remark" :rules="[$rules.required]">
              <v-radio :label="$t('correction_reason_dialogue_option_1')" :value="$t('correction_reason_dialogue_option_1')"></v-radio>
              <v-radio :label="$t('correction_reason_dialogue_option_2')" :value="$t('correction_reason_dialogue_option_2')"></v-radio>
              <v-radio :label="$t('correction_reason_dialogue_option_3')" :value="$t('correction_reason_dialogue_option_3')"></v-radio>
              <v-radio :label="$t('correction_reason_dialogue_option_4')" :value="$t('correction_reason_dialogue_option_4')"></v-radio>
              <v-radio :label="$t('correction_reason_dialogue_option_5')" :value="5"></v-radio>
            </v-radio-group>
            <v-text-field
              class="alignment-text"
              v-model="correctionOtherReason"
              v-if="entireProcessingFormObject.remark == 5"
              :label="$t('correction_reason_dialogue_option_5')"
              :rules="[rules.required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn id="correction_reason_dialogue_complete_correction" class="m-2" color="primary" :loading="loaderRemark" type="submit">
              <span v-html="$t('correction_reason_dialogue_complete_correction')" />
            </v-btn>
            <v-btn id="correction_reason_dialogue_cancel" class="m-2" color="secondary" @click="closeDialog('showDialogCorrectionReason')">
              <span v-html="$t('correction_reason_dialogue_cancel')" />
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-if="entireProcessingFormObject" :retain-focus="false" v-model="showDialogFormSeventyWarning" max-width="75%" id="showDialogFormSeventyWarning">
      <v-card>
        <v-card-title v-html="$t('correction_dialogue_form_status_seventy_title')"></v-card-title>
        <v-card-text>
          <span v-html="$t('correction_dialogue_form_status_seventy_body')" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            id="correction_dialogue_form_status_seventy_complete_correction"
            class="m-2"
            color="primary"
            @click="completeCorrection('showDialogFormSeventyWarning')"
            :loading="loaderRemark"
          >
            <span v-html="$t('correction_dialogue_form_status_seventy_bt_ok')" />
          </v-btn>
          <v-btn
            id="correction_dialogue_form_status_seventy_dialogue_cancel"
            class="m-2"
            color="secondary"
            @click="closeDialog('showDialogFormSeventyWarning')"
          >
            <span v-html="$t('correction_dialogue_form_status_seventy_bt_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { emitter } from '@/main'

const processingFormRowMinimal = {
  bio: 0
}

import { apiURL } from '@/main'
import processingFormPreview from '@/views/mbh/processingForms/processingFormPreview.vue'
import ProcessingFormHeader from './components/processingFormHeader.vue'
import { privileges } from '@/services/privileges'
import { fileCreator, showError } from '@/services/axios'
import rdhComponent from '@/components/rdh/rdhComponent'
import { format } from 'date-fns'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingFormEdit',
  data() {
    return {
      // dialog: false,
      loading: false,
      loaderRemark: false,
      entireProcessingFormObject: {
        formStatusIdent: 0,
        versionNo: 0,
        dairyType: 'DAIRY',
        validUntil: 0
      },
      processingFormRow: processingFormRowMinimal,
      model: {
        validUntil: undefined,
        validFrom: undefined,
        registrationInitial: undefined,
        registrationConfirmed: undefined
      },
      formPart: {
        id: null
      },
      privileges: privileges,
      formModified: false,
      formStatus: [],
      currentformStatus: { ident: 10 },
      openOptions: [{ text: 'closed', value: false }],
      goToNextStep: false,
      producerTotal: undefined,
      desiredStatus: null, //desired status before the workflow question
      showDialogCorrectionReason: false,
      showDialogFormSeventyWarning: false,
      correctionOtherReason: '',
      previousRemark: '',
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  components: {
    processingFormPreview,
    ProcessingFormHeader: ProcessingFormHeader,
    rdhComponent: rdhComponent
  },
  computed: {
    productionFormsReadTerm() {
      const periodYear = format(this.entireProcessingFormObject.validUntil, 'yyyy-MM-dd')
      const periodMonth = format(this.entireProcessingFormObject.validUntil, 'yyyy-MM-dd')
      const period = format(this.entireProcessingFormObject.validUntil, 'yyyy-MM-dd')
      const dairyIdent = this.entireProcessingFormObject.dairyIdent
      const dairyIdentTerm = `Dairy_ident:${dairyIdent}`
      const term = {
        term: dairyIdentTerm,
        periodYear: periodYear,
        periodMonth: periodMonth,
        dairyIdent: dairyIdent,
        period: period,
        size: 50
      }
      return term
    },
    selectedStatusText() {
      return this.formStatus.filter((status) => status.ident == this.currentformStatus.ident)[0][this.$getLangKey()]
    },
    formStatusLength() {
      return this.formStatus.length
    }
  },
  methods: {
    statusWorkflowHelper(currentStatus, desiredStatus) {
      this.desiredStatus = desiredStatus
      if (currentStatus === 70) {
        if (this.entireProcessingFormObject.versionNo === 1 && [30, 40].includes(desiredStatus)) {
          // warn when downgrade from 70 to 30|40
          this.showDialogFormSeventyWarning = true
        } else {
          this.completeCorrection(this.showDialogCorrectionReason)
        }
      } else if (currentStatus === 90 && [20, 30].includes(desiredStatus)) {
        this.showDialogCorrectionReason = true
        if (this.entireProcessingFormObject.remark) {
          this.previousRemark = this.entireProcessingFormObject.remark
        }
      } else {
        this.updateStatus(desiredStatus)
      }
    },
    async updateRemark() {
      if (this.entireProcessingFormObject.remark == 5) {
        this.entireProcessingFormObject.remark = this.correctionOtherReason
      }
      try {
        this.loaderRemark = true
        await this.axios.patch(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            remark: this.previousRemark + ' ' + this.entireProcessingFormObject.remark
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
      } catch (e) {
        showError(e)
      } finally {
        this.loaderRemark = false
      }
    },
    async completeCorrection(dialogName) {
      if (dialogName === 'showDialogCorrectionReason' && !(await this.$refs.correctionReasonForm.validate()).valid) return
      await this.updateRemark()
      await this.updateStatus(this.desiredStatus)
      this.closeDialog(dialogName)
    },
    computeNecessaryInfo() {
      switch (this.entireProcessingFormObject?.formPeriod) {
        case 'MONTHLY':
          if (this.entireProcessingFormObject?.dairyType == 'DIRECT_MARKETER') {
            return 'processing_form_edit_monthly_info_direct_marketer'
          } else {
            return 'processing_form_edit_monthly_info'
          }
        case 'SIX_MONTHLY':
          return 'processing_form_edit_six_monthly_info'
        case 'SEASONAL':
          return 'processing_form_edit_seasonal_info'
        case 'REPEATEDLY_SEASONAL':
          return 'processing_form_edit_repeatedly_seasonal_info'
        default:
          return 'processing_form_edit_default_info'
      }
    },
    generateCustomSubHeaderTextfield() {
      const mbhId = this.entireProcessingFormObject?.dairyIdent ? this.entireProcessingFormObject?.dairyIdent : ''
      const mbhName1 = this.entireProcessingFormObject?.dairyName1 ? this.entireProcessingFormObject?.dairyName1 : ''
      const mbhName2 = this.entireProcessingFormObject?.dairyName2 ? this.entireProcessingFormObject?.dairyName2 : ''
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t('processing_form_edit_subheader'), [mbhId, mbhName1, mbhName2])
    },
    closeDialog(dialogName) {
      this[dialogName] = false
    },
    async createEnversProtocol() {
      try {
        this.loading = true
        const result = await this.axios.get(apiURL + '/processingForms/' + this.$route.params.id + '/reports/historyprotocol', {
          headers: { Accept: 'application/msexcel' },
          responseType: 'blob'
        })
        if (await result) {
          fileCreator(result, 'protocol.xlsx')
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading = false
      }
    },

    goToRelatedFormTemplate() {
      this.$router
        .push({
          name: 'processingFormTemplates_update',
          params: {
            id: this.entireProcessingFormObject.processingFormTemplateId,
            tab: 1
          }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    goBack() {
      this.$router
        .push({
          name: 'processingForms_read'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    exportCsv() {
      this.$refs.processingFormPreviewRef.openDialog()
    },
    submit() {
      this.$refs.processingForm.submit()
    },
    validateForm() {
      this.$refs.processingForm.validate()
    },
    async updateStatus(ident) {
      if (!ident) {
        ident = this.currentformStatus.ident
      }
      // this.entireProcessingFormObject = null
      try {
        this.loaderRemark = true
        const result = await this.axios.patch(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            id: this.$route.params.id,
            formStatus: { ident: ident }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )

        // todo Unschöne Lösung - Die Antwort vom Backend ist nur ein Preview,
        // daher laden wir das ganze Form gleich nochmals.
        this.entireProcessingFormObject = await this.getProcessingForm()
        this.currentformStatus = this.entireProcessingFormObject.formStatus
        this.$refs.processingFormPreviewRef.load()
        emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
      } catch (e) {
        console.error(e)
        showError(e)
      } finally {
        this.loaderRemark = false
      }
    },
    async goForward() {
      try {
        this.loading = true
        let validation = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id + '/calculateValidation',
          {
            id: this.$route.params.id,
            formStatus: { ident: 30 },
            dairy: { id: 10019 }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )

        if (await validation) {
          this.goToNextStep = true
        }
      } catch (e) {
        showError(e)
      } finally {
        if (this.goToNextStep) {
          this.$router
            .push({
              name: 'processingForms_edit_form_step_one',
              params: { id: this.$route.params.id }
            })
            .catch((e) => {
              /* duplicated route */
            })
        }
        this.loading = false
      }
    },
    async getProcessingForm() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        return result.data
      } catch (e) {
        if (this.entireProcessingFormObject?.productionFormId && this.entireProcessingFormObject?.segmentationFormId) {
          showError(e)
        }
      }
    },
    async print() {
      try {
        const result = await this.axios.get('/api/processingForms/' + this.$route.params.id + '/reportTSM1', {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob',
          params: { processingFormId: this.$route.params.id }
        })
        fileCreator(await result)
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      }
    },
    async subsidyShow() {
      try {
        this.loading = true
        // TODO api anpassen
        const result = await this.axios.get('/api/processingForms/' + this.$route.params.id + '/subsidyInvoiceReports', {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob',
          params: { processingFormId: this.$route.params.id }
        })
        fileCreator(await result)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    getformRowTypeByIdent(ident) {
      const result = this.formRowTypes.filter((elx) => elx.ident === Number.parseInt(ident))
      return result.length > 0 ? result[0].id : null
    },
    rowHasBeenUpdated() {
      if (this.currentformStatus.ident == 10) {
        this.currentformStatus.ident = 20
      }
    },
    async updateProcessingForm() {
      this.loading = true
      try {
        const result = await this.axios.patch(
          apiURL + '/processingForms/' + this.$route.params.id + '/updateFromTemplate',
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.entireProcessingFormObject = await this.getProcessingForm()
          this.currentformStatus = this.entireProcessingFormObject.formStatus
          try {
            const response6 = await this.axios.get(apiURL + '/formStatus', {
              headers: { Accept: 'application/json' }
            })
            const data6 = await response6.data
            this.formStatus = data6
            this.formStatus.forEach((element) => {
              element.nameDe = `${element.nameDe} (${element.ident})`
            })
          } catch (e) {
            showError(e)
          }
          this.$refs.processingFormPreviewRef.load()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    hideSubsidy() {
      if (this.entireProcessingFormObject?.subsidyInvoiceReportsCount > 0) return false
      else return true
    }
  },
  watch: {
    formModified: {
      handler() {
        if (!this.formModified) {
          this.updateStatus(10)
        }
      }
    }
  },
  async mounted() {
    this.entireProcessingFormObject = await this.getProcessingForm()
    this.currentformStatus = this.entireProcessingFormObject.formStatus
    try {
      const response6 = await this.axios.get(apiURL + '/formStatus', {
        headers: { Accept: 'application/json' }
      })
      const data6 = await response6.data
      this.formStatus = data6
      this.formStatus.forEach((element) => {
        element.nameDe = `${element.nameDe} (${element.ident})`
      })
    } catch (e) {
      showError(e)
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/variables.scss';

option:disabled {
  color: lightgray !important;
}

ul > li {
  text-align: left;
}

.fooBar {
  display: flex;
  flex-direction: row;
}

.loading-dialog {
  background-color: $dbm-overlay-color !important;
}

.card-spacing {
  padding-top: $spacer-xxxs !important;
  padding-bottom: $spacer-xxxs !important;
}

.disabled-menu {
  opacity: 0.4;
}

#adjustment_btn {
  cursor: pointer;
}

.alignment-text input {
  text-align: initial !important;
}

.margin-left {
  margin-left: $spacer-xxs !important;
}
</style>
